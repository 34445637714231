
window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');

    // DraggableRow
    require('jquery-ui/ui/widgets/draggable.js');
    require('jquery-ui/ui/widgets/sortable.js');
    // DataTables
    require('datatables.net');
    require('datatables.net-buttons');
    require('datatables.net-select');

    // Emoji for Chatroom
    require('emojionearea/dist/emojionearea.js');

} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });

window.io = require('socket.io-client');
window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: window.location.hostname + ':6002',
    auth: {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }
});

/**
 * HTML5 client-side solution for generating PDFs
 */
window.html2canvas = require("html2canvas");
window.jsPDF = require("jspdf");


/**
 * Global Functions for both admin.js and app.js and
 */
window.displayInputFileName = function(input, output) {
    if (input.files && input.files[0]) {
        var filename = input.files[0].name;
        output.innerHTML = filename;
    }
}
 /*** Image File Input ***/
// Image preview for file selected
window.readURLforImgPreview = function(input, output) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
            output.src = e.target.result;
        }
        reader.readAsDataURL(input.files[0]);
    }
}
 /*** Validate File Input Extension ***/
window.fileExtValidate = function(fdata, validExt) {
    var filePath = fdata.value;
    var getFileExt = filePath.substring(filePath.lastIndexOf('.') + 1).toLowerCase();
    var pos = validExt.indexOf(getFileExt);
    if(pos < 0) {
        return false;
    } else {
        return true;
    }
}
 /*** Validate File Input Size ***/
window.fileSizeValidate = function(fdata, maxSize) {
    if (fdata.files && fdata.files[0]) {
        var fsize = fdata.files[0].size / 1024;
        if(fsize > maxSize) {
            return false;
        } else {
            return true;
        }
    }
}